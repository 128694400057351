import React, { useEffect, useState } from 'react';
import { Alert, Form, Input, Button, Checkbox, Spin, Card, Divider } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { StatusCodes } from 'http-status-codes';
import * as store from 'store';

import { logger } from '../utils/loggerUtil';
import { getQueryVariable } from '../utils/queryUtil';
import { getConfig } from '../utils/configUtil';
import { LoginRequestModel } from '../../api';
import { accountApi } from '../apis';

import AuthProvider, { IAuthProviderSettings } from './AuthProvider';

enum LoginStatus {
  None,
  Login,
  Success,
  Error,
}

const USERNAME_KEY = 'username';
const PASSWORD_KEY = 'password';

const LoginForm: React.FC<{}> = () => {
  const [form] = Form.useForm();
  const [rememberMe, setRememberMe] = useState(false);
  const [providers, setProviders] = useState([] as IAuthProviderSettings[]);
  const [status, setStatus] = useState(LoginStatus.None);

  const returnUrl = getQueryVariable('ReturnUrl');

  const loadData = () => {
    const username = store.get(USERNAME_KEY);
    if (username) {
      form.setFieldsValue({
        [USERNAME_KEY]: username,
      });
    }
  };
  useEffect(loadData, []);

  const loadProviders = () => {
    const loadAsync = async () => {
      const config = await getConfig();
      setProviders(config.authentication.providers);
    };
    loadAsync();
  };
  useEffect(loadProviders, []);

  const handleSubmit = async () => {
    setStatus(LoginStatus.Login);

    const options = { withCredentials: true };

    const formFields = form.getFieldsValue();

    const request: LoginRequestModel = {
      username: formFields[USERNAME_KEY],
      password: formFields[PASSWORD_KEY],
      rememberMe: rememberMe,
      returnUrl: returnUrl,
    };

    try {
      const response = await accountApi.processAccountLogin(request, options);
      if (response.status === StatusCodes.OK) {
        store.set(USERNAME_KEY, request.username);
        setStatus(LoginStatus.Success);
        window.location.href = response.data.redirectUrl || '/';
      } else {
        logger.warn('Ошибка входа', response);
        setStatus(LoginStatus.Error);
      }
    } catch (e) {
      logger.warn('Ошибка входа', e);
      setStatus(LoginStatus.Error);
    }
  };

  const renderProviders = () => {
    const results = Object.values(providers)
      .filter((provider) => provider.visible)
      .map((provider) => {
        return <AuthProvider key={provider.scheme} returnUrl={returnUrl} settings={provider} />;
      });
    if (results.length) {
      results.unshift(<Divider key="divider" />);
    }
    return results;
  };

  const renderStatusAlert = () => {
    switch (status) {
      case LoginStatus.Login:
        return (
          <Spin>
            <Alert message="Выполняется вход" type="info" />
          </Spin>
        );

      case LoginStatus.Error:
        return <Alert message="Некорректные учетные данные" type="error" />;

      case LoginStatus.Success:
        return (
          <Spin>
            <Alert message="Вход выполнен, выполняется перенаправление" type="success" />
          </Spin>
        );
      default:
        return null;
    }
  };

  const renderStatusBlock = () => {
    const alert = renderStatusAlert();
    if (!alert) {
      return null;
    }
    return (
      <React.Fragment>
        <Divider />
        {alert}
      </React.Fragment>
    );
  };

  return (
    <Card>
      <Form className="login-form" form={form} name="login_form" onFinish={() => handleSubmit()}>
        <Form.Item
          name={USERNAME_KEY}
          rules={[{ required: true, message: 'Пожалуйста, укажите идентификационные данные.' }]}
        >
          <Input
            placeholder="Логин или электронная почта"
            prefix={<UserOutlined className="site-form-item-icon" />}
            size="large"
          />
        </Form.Item>

        <Form.Item name={PASSWORD_KEY} rules={[{ required: true, message: 'Пожалуйста, укажите пароль.' }]}>
          <Input
            placeholder="Пароль"
            prefix={<LockOutlined className="site-form-item-icon" />}
            size="large"
            type="password"
          />
        </Form.Item>

        <Form.Item>
          <Form.Item noStyle name="rememberMe" valuePropName="checked">
            <Checkbox checked={rememberMe} onChange={(e) => setRememberMe(e.target.checked)}>
              Запомнить меня
            </Checkbox>
          </Form.Item>
        </Form.Item>

        <Form.Item>
          <Button
            className="login-form-button"
            htmlType="submit"
            title="Войти, используя свою учетную запись ELMA Bot"
            type="primary"
          >
            Войти, используя свой аккаунт ELMA Bot
          </Button>
        </Form.Item>
      </Form>
      {renderProviders()}
      {renderStatusBlock()}
    </Card>
  );
};

export default LoginForm;
