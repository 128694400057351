import React from 'react';

import LoginForm from '../components/LoginForm';

const LoginPage: React.FC<{}> = () => {
  return (
    <div>
      <LoginForm />
    </div>
  );
};

export default LoginPage;
