import React from 'react';

import AccountInfo from '../components/AccountInfo';

const InfoPage: React.FC<{}> = () => {
  return (
    <div>
      <AccountInfo />
    </div>
  );
};

export default InfoPage;
