import React from 'react';

import ErrorInfo from '../components/ErrorInfo';

const ErrorPage: React.FC<{}> = () => {
  return (
    <div>
      <ErrorInfo />
    </div>
  );
};

export default ErrorPage;
