import React, { useState, useEffect } from 'react';
import { Button, Result, Spin, Card } from 'antd';
import { StatusCodes } from 'http-status-codes';

import { logger } from '../utils/loggerUtil';
import { AccountInfoModel } from '../../api';
import { accountApi } from '../apis';

enum LoadingStatus {
  Loading,
  Success,
  Error,
}

const AccountInfo: React.FC<{}> = () => {
  const [status, setStatus] = useState(LoadingStatus.Loading);
  const [account, setAccount] = useState({} as AccountInfoModel);
  const [error, setError] = useState('');

  const loadDataAsync = async () => {
    try {
      const response = await accountApi.getAccountInfo();
      if (response.status === StatusCodes.OK) {
        setAccount(response.data);
        setStatus(LoadingStatus.Success);
      } else {
        logger.error('Ошибка загрузки данных', response.statusText);
        setError(response.statusText);
        setStatus(LoadingStatus.Error);
      }
    } catch (e) {
      logger.error('Ошибка загрузки данных', e);
      setError(e.messsage);
      setStatus(LoadingStatus.Error);
    }
  };
  const loadData = () => {
    loadDataAsync();
  };
  useEffect(loadData, []);

  const getExtra = () => [
    <Button key="return" href="/" type="link">
      Вернуться на страницу входа
    </Button>,
  ];

  const getInfo = () => {
    switch (status) {
      case LoadingStatus.Loading:
        return <Result extra={<Spin size="large" />} status="info" />;

      case LoadingStatus.Success:
        return (
          <Result
            extra={getExtra()}
            status={account.authenticated ? 'success' : 'warning'}
            title={account.username || '-'}
          />
        );

      case LoadingStatus.Error:
        return <Result extra={getExtra()} status="error" title={error} />;
    }
  };

  return <Card>{getInfo()}</Card>;
};

export default AccountInfo;
