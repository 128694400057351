import React, { useState, useEffect } from 'react';
import { Button, Result, Spin, Card } from 'antd';
import { StatusCodes } from 'http-status-codes';

import { logger } from '../utils/loggerUtil';
import { getQueryVariable } from '../utils/queryUtil';
import { LogoutRequestModel } from '../../api';
import { accountApi } from '../apis';

enum LogoutStatus {
  Logout,
  Success,
  Error,
}

const LogoutPanel: React.FC<{}> = () => {
  const [status, setStatus] = useState(LogoutStatus.Logout);
  const [error, setError] = useState('');

  const processLogoutAsync = async () => {
    const logoutId = getQueryVariable('logoutId');
    const options = { withCredentials: true };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let result: any = {};

    try {
      const response = await accountApi.getLogoutInfo(logoutId, options);

      if (response.status !== StatusCodes.OK) {
        logger.error('Ошибка выхода', logoutId, response);
        setStatus(LogoutStatus.Error);
        setError(response.statusText);
        return;
      }

      result = response.data;
    } catch (e) {
      logger.error('Ошибка выхода', logoutId, e);
      setStatus(LogoutStatus.Error);
      setError(e.message);
      return;
    }

    // TODO: Добавить prompt.
    if (result.showLogoutPrompt) {
      const request: LogoutRequestModel = { logoutId };
      try {
        const response = await accountApi.processAccountLogout(request, options);

        if (response.status !== StatusCodes.OK) {
          logger.error('Ошибка выхода', request, response);
          setStatus(LogoutStatus.Error);
          setError(response.statusText);
          return;
        }

        result = response.data;
      } catch (e) {
        logger.error('Ошибка выхода', request, e);
        setStatus(LogoutStatus.Error);
        setError(e.message);
        return;
      }
    }

    setStatus(LogoutStatus.Success);
    setError('');
    window.location.href = result.postLogoutRedirectUri || '/';
  };

  const processLogout = () => {
    processLogoutAsync();
  };

  useEffect(processLogout, []);

  const getLogoutResult = () => {
    switch (status) {
      case LogoutStatus.Logout:
        return <Result extra={<Spin size="large" />} status="info" title="Выполняется выход из системы" />;

      case LogoutStatus.Success:
        return (
          <Result extra={<Spin size="large" />} status="success" title="Выход выполнен, выполняется перенаправление" />
        );

      case LogoutStatus.Error:
        return (
          <Result
            extra={[
              <Button key="return" href="/" type="link">
                Вернуться на страницу входа
              </Button>,
            ]}
            status="error"
            subTitle={error}
            title="Во время выхода произошла ошибка"
          />
        );
    }
  };

  return <Card>{getLogoutResult()}</Card>;
};

export default LogoutPanel;
