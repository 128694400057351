// tslint:disable
/**
 * ELMA Bot Auth API
 * ELMA Bot Auth API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * Модель информации о приложении.
 * @export
 * @interface AboutModel
 */
export interface AboutModel {
    /**
     * Наименование.
     * @type {string}
     * @memberof AboutModel
     */
    name?: string | null;
    /**
     * Описание.
     * @type {string}
     * @memberof AboutModel
     */
    description?: string | null;
    /**
     * Версия.
     * @type {string}
     * @memberof AboutModel
     */
    version?: string | null;
}
/**
 * Модель информации об аккаунте.
 * @export
 * @interface AccountInfoModel
 */
export interface AccountInfoModel {
    /**
     * Аутентифицирован?
     * @type {boolean}
     * @memberof AccountInfoModel
     */
    authenticated?: boolean;
    /**
     * Иvя пользователя.
     * @type {string}
     * @memberof AccountInfoModel
     */
    username?: string | null;
}
/**
 * Модель информации об ошибке.
 * @export
 * @interface ErrorInfoModel
 */
export interface ErrorInfoModel {
    /**
     * Сообщение.
     * @type {string}
     * @memberof ErrorInfoModel
     */
    message?: string | null;
    /**
     * Описание.
     * @type {string}
     * @memberof ErrorInfoModel
     */
    description?: string | null;
}
/**
 * Модель информации о работоспособности.
 * @export
 * @interface HealthModel
 */
export interface HealthModel {
    /**
     * Статус работоспособности.
     * @type {string}
     * @memberof HealthModel
     */
    status?: string | null;
}
/**
 * Модель запроса на вход.
 * @export
 * @interface LoginRequestModel
 */
export interface LoginRequestModel {
    /**
     * Логин.
     * @type {string}
     * @memberof LoginRequestModel
     */
    username?: string | null;
    /**
     * Пароль.
     * @type {string}
     * @memberof LoginRequestModel
     */
    password?: string | null;
    /**
     * Запомнить меня?
     * @type {boolean}
     * @memberof LoginRequestModel
     */
    rememberMe?: boolean;
    /**
     * URL возврата.
     * @type {string}
     * @memberof LoginRequestModel
     */
    returnUrl?: string | null;
}
/**
 * Модель ответа на вход.
 * @export
 * @interface LoginResponseModel
 */
export interface LoginResponseModel {
    /**
     * URL перенаправления.
     * @type {string}
     * @memberof LoginResponseModel
     */
    redirectUrl?: string | null;
}
/**
 * Модель информации о выходе.
 * @export
 * @interface LogoutInfoModel
 */
export interface LogoutInfoModel {
    /**
     * ИД выхода.
     * @type {string}
     * @memberof LogoutInfoModel
     */
    logoutId?: string | null;
    /**
     * Показывать подтверждение?
     * @type {boolean}
     * @memberof LogoutInfoModel
     */
    showLogoutPrompt?: boolean;
}
/**
 * Модель запроса на выход.
 * @export
 * @interface LogoutRequestModel
 */
export interface LogoutRequestModel {
    /**
     * ИД выхода.
     * @type {string}
     * @memberof LogoutRequestModel
     */
    logoutId?: string | null;
}
/**
 * Модель ответа на выход.
 * @export
 * @interface LogoutResponseModel
 */
export interface LogoutResponseModel {
    /**
     * ИД выхода.
     * @type {string}
     * @memberof LogoutResponseModel
     */
    logoutId?: string | null;
    /**
     * Показывать подтверждение?
     * @type {boolean}
     * @memberof LogoutResponseModel
     */
    showLogoutPrompt?: boolean;
    /**
     * Наименование клиента.
     * @type {string}
     * @memberof LogoutResponseModel
     */
    clientName?: string | null;
    /**
     * URL фрейма для выхода.
     * @type {string}
     * @memberof LogoutResponseModel
     */
    signOutIframeUrl?: string | null;
    /**
     * URL перенаправления после выхода.
     * @type {string}
     * @memberof LogoutResponseModel
     */
    postLogoutRedirectUri?: string | null;
}
/**
 * 
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
    [key: string]: any | any;

    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    title?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProblemDetails
     */
    status?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    detail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    instance?: string | null;
}
/**
 * Запрос на создание роли.
 * @export
 * @interface RoleCreationRequest
 */
export interface RoleCreationRequest {
    /**
     * Наименование роли.
     * @type {string}
     * @memberof RoleCreationRequest
     */
    name: string;
    /**
     * Описание роли.
     * @type {string}
     * @memberof RoleCreationRequest
     */
    description?: string | null;
}
/**
 * Ответ на создание роли.
 * @export
 * @interface RoleCreationResponse
 */
export interface RoleCreationResponse {
    /**
     * ИД роли.
     * @type {string}
     * @memberof RoleCreationResponse
     */
    id?: string | null;
}
/**
 * Модель роли.
 * @export
 * @interface RoleModel
 */
export interface RoleModel {
    /**
     * ИД роли.
     * @type {string}
     * @memberof RoleModel
     */
    id?: string | null;
    /**
     * Наименование роли.
     * @type {string}
     * @memberof RoleModel
     */
    name?: string | null;
    /**
     * Описание роли.
     * @type {string}
     * @memberof RoleModel
     */
    description?: string | null;
}
/**
 * 
 * @export
 * @interface RoleModelPaginationResponse
 */
export interface RoleModelPaginationResponse {
    /**
     * 
     * @type {Array<RoleModel>}
     * @memberof RoleModelPaginationResponse
     */
    items?: Array<RoleModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof RoleModelPaginationResponse
     */
    hasMore?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RoleModelPaginationResponse
     */
    pageIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof RoleModelPaginationResponse
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof RoleModelPaginationResponse
     */
    totalItemCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RoleModelPaginationResponse
     */
    totalPageCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RoleModelPaginationResponse
     */
    fromItemInclusive?: number;
    /**
     * 
     * @type {number}
     * @memberof RoleModelPaginationResponse
     */
    toItemExclusive?: number;
}
/**
 * Запрос на обновление роли.
 * @export
 * @interface RoleUpdatingRequest
 */
export interface RoleUpdatingRequest {
    /**
     * Наименование роли.
     * @type {string}
     * @memberof RoleUpdatingRequest
     */
    name: string;
    /**
     * Описание роли.
     * @type {string}
     * @memberof RoleUpdatingRequest
     */
    description?: string | null;
}
/**
 * Запрос на создание тенанта.
 * @export
 * @interface TenantCreationRequest
 */
export interface TenantCreationRequest {
    /**
     * Наименование тенанта.
     * @type {string}
     * @memberof TenantCreationRequest
     */
    name: string;
    /**
     * Описание тенанта.
     * @type {string}
     * @memberof TenantCreationRequest
     */
    description?: string | null;
}
/**
 * Ответ на создание тенанта.
 * @export
 * @interface TenantCreationResponse
 */
export interface TenantCreationResponse {
    /**
     * ИД тенанта.
     * @type {string}
     * @memberof TenantCreationResponse
     */
    id?: string | null;
}
/**
 * Модель тенанта.
 * @export
 * @interface TenantModel
 */
export interface TenantModel {
    /**
     * ИД тенанта.
     * @type {string}
     * @memberof TenantModel
     */
    id?: string | null;
    /**
     * Наименование тенанта.
     * @type {string}
     * @memberof TenantModel
     */
    name?: string | null;
    /**
     * Описание тенанта.
     * @type {string}
     * @memberof TenantModel
     */
    description?: string | null;
}
/**
 * 
 * @export
 * @interface TenantModelPaginationResponse
 */
export interface TenantModelPaginationResponse {
    /**
     * 
     * @type {Array<TenantModel>}
     * @memberof TenantModelPaginationResponse
     */
    items?: Array<TenantModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof TenantModelPaginationResponse
     */
    hasMore?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TenantModelPaginationResponse
     */
    pageIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof TenantModelPaginationResponse
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof TenantModelPaginationResponse
     */
    totalItemCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TenantModelPaginationResponse
     */
    totalPageCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TenantModelPaginationResponse
     */
    fromItemInclusive?: number;
    /**
     * 
     * @type {number}
     * @memberof TenantModelPaginationResponse
     */
    toItemExclusive?: number;
}
/**
 * Запрос на обновление тенанта.
 * @export
 * @interface TenantUpdatingRequest
 */
export interface TenantUpdatingRequest {
    /**
     * Наименование тенанта.
     * @type {string}
     * @memberof TenantUpdatingRequest
     */
    name: string;
    /**
     * Описание тенанта.
     * @type {string}
     * @memberof TenantUpdatingRequest
     */
    description?: string | null;
}
/**
 * Запрос на создание пользователя.
 * @export
 * @interface UserCreationRequest
 */
export interface UserCreationRequest {
    /**
     * Логин пользователя.
     * @type {string}
     * @memberof UserCreationRequest
     */
    login: string;
    /**
     * Пароль пользователя.
     * @type {string}
     * @memberof UserCreationRequest
     */
    password: string;
    /**
     * Email пользователя.
     * @type {string}
     * @memberof UserCreationRequest
     */
    email: string;
    /**
     * Номер телефона пользователя.
     * @type {string}
     * @memberof UserCreationRequest
     */
    phoneNumber: string;
    /**
     * Полное имя пользователя.
     * @type {string}
     * @memberof UserCreationRequest
     */
    fullName: string;
    /**
     * Краткое имя пользователя.
     * @type {string}
     * @memberof UserCreationRequest
     */
    shortName: string;
    /**
     * Фамилия пользователя.
     * @type {string}
     * @memberof UserCreationRequest
     */
    familyName: string;
    /**
     * Имя пользователя.
     * @type {string}
     * @memberof UserCreationRequest
     */
    givenName: string;
    /**
     * Отчество пользователя.
     * @type {string}
     * @memberof UserCreationRequest
     */
    middleName?: string | null;
    /**
     * Наименование тенанта пользователя.
     * @type {string}
     * @memberof UserCreationRequest
     */
    tenantName?: string | null;
    /**
     * Наименования ролей пользователя.
     * @type {string}
     * @memberof UserCreationRequest
     */
    roleNames?: string | null;
}
/**
 * Ответ на создание пользователя.
 * @export
 * @interface UserCreationResponse
 */
export interface UserCreationResponse {
    /**
     * ИД пользователя.
     * @type {string}
     * @memberof UserCreationResponse
     */
    id?: string | null;
}
/**
 * Модель пользователя.
 * @export
 * @interface UserModel
 */
export interface UserModel {
    /**
     * ИД пользователя.
     * @type {string}
     * @memberof UserModel
     */
    id?: string | null;
    /**
     * Логин пользователя.
     * @type {string}
     * @memberof UserModel
     */
    login?: string | null;
    /**
     * Пароль пользователя.
     * @type {string}
     * @memberof UserModel
     */
    password?: string | null;
    /**
     * Email пользователя.
     * @type {string}
     * @memberof UserModel
     */
    email?: string | null;
    /**
     * Номер телефона пользователя.
     * @type {string}
     * @memberof UserModel
     */
    phoneNumber?: string | null;
    /**
     * Полное имя пользователя.
     * @type {string}
     * @memberof UserModel
     */
    fullName?: string | null;
    /**
     * Краткое имя пользователя.
     * @type {string}
     * @memberof UserModel
     */
    shortName?: string | null;
    /**
     * Фамилия пользователя.
     * @type {string}
     * @memberof UserModel
     */
    familyName?: string | null;
    /**
     * Имя пользователя.
     * @type {string}
     * @memberof UserModel
     */
    givenName?: string | null;
    /**
     * Отчество пользователя.
     * @type {string}
     * @memberof UserModel
     */
    middleName?: string | null;
    /**
     * Наименование тенанта пользователя.
     * @type {string}
     * @memberof UserModel
     */
    tenantName?: string | null;
    /**
     * Наименования ролей пользователя.
     * @type {string}
     * @memberof UserModel
     */
    roleNames?: string | null;
}
/**
 * 
 * @export
 * @interface UserModelPaginationResponse
 */
export interface UserModelPaginationResponse {
    /**
     * 
     * @type {Array<UserModel>}
     * @memberof UserModelPaginationResponse
     */
    items?: Array<UserModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserModelPaginationResponse
     */
    hasMore?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UserModelPaginationResponse
     */
    pageIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof UserModelPaginationResponse
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof UserModelPaginationResponse
     */
    totalItemCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UserModelPaginationResponse
     */
    totalPageCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UserModelPaginationResponse
     */
    fromItemInclusive?: number;
    /**
     * 
     * @type {number}
     * @memberof UserModelPaginationResponse
     */
    toItemExclusive?: number;
}
/**
 * Запрос на обновление пользователя.
 * @export
 * @interface UserUpdatingRequest
 */
export interface UserUpdatingRequest {
    /**
     * Логин пользователя.
     * @type {string}
     * @memberof UserUpdatingRequest
     */
    login: string;
    /**
     * Email пользователя.
     * @type {string}
     * @memberof UserUpdatingRequest
     */
    email: string;
    /**
     * Номер телефона пользователя.
     * @type {string}
     * @memberof UserUpdatingRequest
     */
    phoneNumber: string;
    /**
     * Полное имя пользователя.
     * @type {string}
     * @memberof UserUpdatingRequest
     */
    fullName: string;
    /**
     * Краткое имя пользователя.
     * @type {string}
     * @memberof UserUpdatingRequest
     */
    shortName: string;
    /**
     * Фамилия пользователя.
     * @type {string}
     * @memberof UserUpdatingRequest
     */
    familyName: string;
    /**
     * Имя пользователя.
     * @type {string}
     * @memberof UserUpdatingRequest
     */
    givenName: string;
    /**
     * Отчество пользователя.
     * @type {string}
     * @memberof UserUpdatingRequest
     */
    middleName?: string | null;
    /**
     * Наименование тенанта пользователя.
     * @type {string}
     * @memberof UserUpdatingRequest
     */
    tenantName?: string | null;
    /**
     * Наименования ролей пользователя.
     * @type {string}
     * @memberof UserUpdatingRequest
     */
    roleNames?: string | null;
}

/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить информацию об аккаунте.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountInfo: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/identity/account/info`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить информацию об ошибке.
         * @param {string} [errorId] ИД выхоошибкида.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getErrorInfo: async (errorId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/identity/account/error`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (errorId !== undefined) {
                localVarQueryParameter['errorId'] = errorId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить информацию о выходе.
         * @param {string} [logoutId] ИД выхода.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogoutInfo: async (logoutId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/identity/account/logout`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (logoutId !== undefined) {
                localVarQueryParameter['logoutId'] = logoutId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить вход.
         * @param {LoginRequestModel} [loginRequestModel] Запрос на вход.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processAccountLogin: async (loginRequestModel?: LoginRequestModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/identity/account/login`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof loginRequestModel !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(loginRequestModel !== undefined ? loginRequestModel : {}) : (loginRequestModel || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить выход.
         * @param {LogoutRequestModel} [logoutRequestModel] Запрос на выход.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processAccountLogout: async (logoutRequestModel?: LogoutRequestModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/identity/account/logout`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof logoutRequestModel !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(logoutRequestModel !== undefined ? logoutRequestModel : {}) : (logoutRequestModel || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить информацию об аккаунте.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccountInfo(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountInfoModel>> {
            const localVarAxiosArgs = await AccountApiAxiosParamCreator(configuration).getAccountInfo(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получить информацию об ошибке.
         * @param {string} [errorId] ИД выхоошибкида.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getErrorInfo(errorId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ErrorInfoModel>> {
            const localVarAxiosArgs = await AccountApiAxiosParamCreator(configuration).getErrorInfo(errorId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получить информацию о выходе.
         * @param {string} [logoutId] ИД выхода.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLogoutInfo(logoutId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LogoutInfoModel>> {
            const localVarAxiosArgs = await AccountApiAxiosParamCreator(configuration).getLogoutInfo(logoutId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Выполнить вход.
         * @param {LoginRequestModel} [loginRequestModel] Запрос на вход.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processAccountLogin(loginRequestModel?: LoginRequestModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponseModel>> {
            const localVarAxiosArgs = await AccountApiAxiosParamCreator(configuration).processAccountLogin(loginRequestModel, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Выполнить выход.
         * @param {LogoutRequestModel} [logoutRequestModel] Запрос на выход.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processAccountLogout(logoutRequestModel?: LogoutRequestModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LogoutResponseModel>> {
            const localVarAxiosArgs = await AccountApiAxiosParamCreator(configuration).processAccountLogout(logoutRequestModel, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Получить информацию об аккаунте.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountInfo(options?: any): AxiosPromise<AccountInfoModel> {
            return AccountApiFp(configuration).getAccountInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить информацию об ошибке.
         * @param {string} [errorId] ИД выхоошибкида.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getErrorInfo(errorId?: string, options?: any): AxiosPromise<ErrorInfoModel> {
            return AccountApiFp(configuration).getErrorInfo(errorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить информацию о выходе.
         * @param {string} [logoutId] ИД выхода.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogoutInfo(logoutId?: string, options?: any): AxiosPromise<LogoutInfoModel> {
            return AccountApiFp(configuration).getLogoutInfo(logoutId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить вход.
         * @param {LoginRequestModel} [loginRequestModel] Запрос на вход.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processAccountLogin(loginRequestModel?: LoginRequestModel, options?: any): AxiosPromise<LoginResponseModel> {
            return AccountApiFp(configuration).processAccountLogin(loginRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить выход.
         * @param {LogoutRequestModel} [logoutRequestModel] Запрос на выход.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processAccountLogout(logoutRequestModel?: LogoutRequestModel, options?: any): AxiosPromise<LogoutResponseModel> {
            return AccountApiFp(configuration).processAccountLogout(logoutRequestModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
    /**
     * 
     * @summary Получить информацию об аккаунте.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public getAccountInfo(options?: any) {
        return AccountApiFp(this.configuration).getAccountInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить информацию об ошибке.
     * @param {string} [errorId] ИД выхоошибкида.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public getErrorInfo(errorId?: string, options?: any) {
        return AccountApiFp(this.configuration).getErrorInfo(errorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить информацию о выходе.
     * @param {string} [logoutId] ИД выхода.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public getLogoutInfo(logoutId?: string, options?: any) {
        return AccountApiFp(this.configuration).getLogoutInfo(logoutId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить вход.
     * @param {LoginRequestModel} [loginRequestModel] Запрос на вход.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public processAccountLogin(loginRequestModel?: LoginRequestModel, options?: any) {
        return AccountApiFp(this.configuration).processAccountLogin(loginRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить выход.
     * @param {LogoutRequestModel} [logoutRequestModel] Запрос на выход.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public processAccountLogout(logoutRequestModel?: LogoutRequestModel, options?: any) {
        return AccountApiFp(this.configuration).processAccountLogout(logoutRequestModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ApplicationApi - axios parameter creator
 * @export
 */
export const ApplicationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить информацию о приложении.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAboutInfo: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/info/application/about`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить информацию о работоспособности.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealthInfo: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/info/application/health`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApplicationApi - functional programming interface
 * @export
 */
export const ApplicationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить информацию о приложении.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAboutInfo(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AboutModel>> {
            const localVarAxiosArgs = await ApplicationApiAxiosParamCreator(configuration).getAboutInfo(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получить информацию о работоспособности.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHealthInfo(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HealthModel>> {
            const localVarAxiosArgs = await ApplicationApiAxiosParamCreator(configuration).getHealthInfo(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ApplicationApi - factory interface
 * @export
 */
export const ApplicationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Получить информацию о приложении.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAboutInfo(options?: any): AxiosPromise<AboutModel> {
            return ApplicationApiFp(configuration).getAboutInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить информацию о работоспособности.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealthInfo(options?: any): AxiosPromise<HealthModel> {
            return ApplicationApiFp(configuration).getHealthInfo(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApplicationApi - object-oriented interface
 * @export
 * @class ApplicationApi
 * @extends {BaseAPI}
 */
export class ApplicationApi extends BaseAPI {
    /**
     * 
     * @summary Получить информацию о приложении.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public getAboutInfo(options?: any) {
        return ApplicationApiFp(this.configuration).getAboutInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить информацию о работоспособности.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public getHealthInfo(options?: any) {
        return ApplicationApiFp(this.configuration).getHealthInfo(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConfigurationApi - axios parameter creator
 * @export
 */
export const ConfigurationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить конфигурацию клиента.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientConfig: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/info/configuration/client`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConfigurationApi - functional programming interface
 * @export
 */
export const ConfigurationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить конфигурацию клиента.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClientConfig(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ConfigurationApiAxiosParamCreator(configuration).getClientConfig(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ConfigurationApi - factory interface
 * @export
 */
export const ConfigurationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Получить конфигурацию клиента.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientConfig(options?: any): AxiosPromise<void> {
            return ConfigurationApiFp(configuration).getClientConfig(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConfigurationApi - object-oriented interface
 * @export
 * @class ConfigurationApi
 * @extends {BaseAPI}
 */
export class ConfigurationApi extends BaseAPI {
    /**
     * 
     * @summary Получить конфигурацию клиента.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public getClientConfig(options?: any) {
        return ConfigurationApiFp(this.configuration).getClientConfig(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RoleApi - axios parameter creator
 * @export
 */
export const RoleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Создать роль.
         * @param {RoleCreationRequest} [roleCreationRequest] Запрос на создание роли.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRole: async (roleCreationRequest?: RoleCreationRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/membership/roles`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof roleCreationRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(roleCreationRequest !== undefined ? roleCreationRequest : {}) : (roleCreationRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удалить роль.
         * @param {string} id ИД роли.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRole: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteRole.');
            }
            const localVarPath = `/api/v1/membership/roles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить роль.
         * @param {string} id ИД роли.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRole: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getRole.');
            }
            const localVarPath = `/api/v1/membership/roles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить поиск ролей.
         * @param {object} [filtration] Параметры фильтрации.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchRoles: async (filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/membership/roles`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (filtration !== undefined) {
                localVarQueryParameter['filtration'] = filtration;
            }

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить роль.
         * @param {string} id ИД роли.
         * @param {RoleUpdatingRequest} [roleUpdatingRequest] Запрос на обновление роли.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRole: async (id: string, roleUpdatingRequest?: RoleUpdatingRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateRole.');
            }
            const localVarPath = `/api/v1/membership/roles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof roleUpdatingRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(roleUpdatingRequest !== undefined ? roleUpdatingRequest : {}) : (roleUpdatingRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RoleApi - functional programming interface
 * @export
 */
export const RoleApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Создать роль.
         * @param {RoleCreationRequest} [roleCreationRequest] Запрос на создание роли.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRole(roleCreationRequest?: RoleCreationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleCreationResponse>> {
            const localVarAxiosArgs = await RoleApiAxiosParamCreator(configuration).createRole(roleCreationRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Удалить роль.
         * @param {string} id ИД роли.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRole(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await RoleApiAxiosParamCreator(configuration).deleteRole(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получить роль.
         * @param {string} id ИД роли.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRole(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleModel>> {
            const localVarAxiosArgs = await RoleApiAxiosParamCreator(configuration).getRole(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Выполнить поиск ролей.
         * @param {object} [filtration] Параметры фильтрации.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchRoles(filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleModelPaginationResponse>> {
            const localVarAxiosArgs = await RoleApiAxiosParamCreator(configuration).searchRoles(filtration, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Обновить роль.
         * @param {string} id ИД роли.
         * @param {RoleUpdatingRequest} [roleUpdatingRequest] Запрос на обновление роли.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRole(id: string, roleUpdatingRequest?: RoleUpdatingRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await RoleApiAxiosParamCreator(configuration).updateRole(id, roleUpdatingRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * RoleApi - factory interface
 * @export
 */
export const RoleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Создать роль.
         * @param {RoleCreationRequest} [roleCreationRequest] Запрос на создание роли.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRole(roleCreationRequest?: RoleCreationRequest, options?: any): AxiosPromise<RoleCreationResponse> {
            return RoleApiFp(configuration).createRole(roleCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Удалить роль.
         * @param {string} id ИД роли.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRole(id: string, options?: any): AxiosPromise<void> {
            return RoleApiFp(configuration).deleteRole(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить роль.
         * @param {string} id ИД роли.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRole(id: string, options?: any): AxiosPromise<RoleModel> {
            return RoleApiFp(configuration).getRole(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить поиск ролей.
         * @param {object} [filtration] Параметры фильтрации.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchRoles(filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<RoleModelPaginationResponse> {
            return RoleApiFp(configuration).searchRoles(filtration, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить роль.
         * @param {string} id ИД роли.
         * @param {RoleUpdatingRequest} [roleUpdatingRequest] Запрос на обновление роли.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRole(id: string, roleUpdatingRequest?: RoleUpdatingRequest, options?: any): AxiosPromise<object> {
            return RoleApiFp(configuration).updateRole(id, roleUpdatingRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RoleApi - object-oriented interface
 * @export
 * @class RoleApi
 * @extends {BaseAPI}
 */
export class RoleApi extends BaseAPI {
    /**
     * 
     * @summary Создать роль.
     * @param {RoleCreationRequest} [roleCreationRequest] Запрос на создание роли.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public createRole(roleCreationRequest?: RoleCreationRequest, options?: any) {
        return RoleApiFp(this.configuration).createRole(roleCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Удалить роль.
     * @param {string} id ИД роли.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public deleteRole(id: string, options?: any) {
        return RoleApiFp(this.configuration).deleteRole(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить роль.
     * @param {string} id ИД роли.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public getRole(id: string, options?: any) {
        return RoleApiFp(this.configuration).getRole(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить поиск ролей.
     * @param {object} [filtration] Параметры фильтрации.
     * @param {number} [pageIndex] 
     * @param {number} [pageSize] 
     * @param {boolean} [withTotal] 
     * @param {number} [fromItemInclusive] 
     * @param {number} [toItemExclusive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public searchRoles(filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any) {
        return RoleApiFp(this.configuration).searchRoles(filtration, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить роль.
     * @param {string} id ИД роли.
     * @param {RoleUpdatingRequest} [roleUpdatingRequest] Запрос на обновление роли.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public updateRole(id: string, roleUpdatingRequest?: RoleUpdatingRequest, options?: any) {
        return RoleApiFp(this.configuration).updateRole(id, roleUpdatingRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TenantApi - axios parameter creator
 * @export
 */
export const TenantApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Создать тенанта.
         * @param {TenantCreationRequest} [tenantCreationRequest] Запрос на создание тенанта.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTenant: async (tenantCreationRequest?: TenantCreationRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/general/tenants`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof tenantCreationRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(tenantCreationRequest !== undefined ? tenantCreationRequest : {}) : (tenantCreationRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удалить тенант.
         * @param {string} id ИД тенанта.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTenant: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteTenant.');
            }
            const localVarPath = `/api/v1/general/tenants/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить тенанта.
         * @param {string} id ИД тенанта.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenant: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getTenant.');
            }
            const localVarPath = `/api/v1/general/tenants/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить поиск тенантов.
         * @param {object} [filtration] Параметры фильтрации.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTenants: async (filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/general/tenants`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (filtration !== undefined) {
                localVarQueryParameter['filtration'] = filtration;
            }

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить тенанта.
         * @param {string} id ИД тенанта.
         * @param {TenantUpdatingRequest} [tenantUpdatingRequest] Запрос на обновление тенанта.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTenant: async (id: string, tenantUpdatingRequest?: TenantUpdatingRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateTenant.');
            }
            const localVarPath = `/api/v1/general/tenants/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof tenantUpdatingRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(tenantUpdatingRequest !== undefined ? tenantUpdatingRequest : {}) : (tenantUpdatingRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TenantApi - functional programming interface
 * @export
 */
export const TenantApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Создать тенанта.
         * @param {TenantCreationRequest} [tenantCreationRequest] Запрос на создание тенанта.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTenant(tenantCreationRequest?: TenantCreationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantCreationResponse>> {
            const localVarAxiosArgs = await TenantApiAxiosParamCreator(configuration).createTenant(tenantCreationRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Удалить тенант.
         * @param {string} id ИД тенанта.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTenant(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await TenantApiAxiosParamCreator(configuration).deleteTenant(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получить тенанта.
         * @param {string} id ИД тенанта.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTenant(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantModel>> {
            const localVarAxiosArgs = await TenantApiAxiosParamCreator(configuration).getTenant(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Выполнить поиск тенантов.
         * @param {object} [filtration] Параметры фильтрации.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchTenants(filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantModelPaginationResponse>> {
            const localVarAxiosArgs = await TenantApiAxiosParamCreator(configuration).searchTenants(filtration, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Обновить тенанта.
         * @param {string} id ИД тенанта.
         * @param {TenantUpdatingRequest} [tenantUpdatingRequest] Запрос на обновление тенанта.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTenant(id: string, tenantUpdatingRequest?: TenantUpdatingRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await TenantApiAxiosParamCreator(configuration).updateTenant(id, tenantUpdatingRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TenantApi - factory interface
 * @export
 */
export const TenantApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Создать тенанта.
         * @param {TenantCreationRequest} [tenantCreationRequest] Запрос на создание тенанта.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTenant(tenantCreationRequest?: TenantCreationRequest, options?: any): AxiosPromise<TenantCreationResponse> {
            return TenantApiFp(configuration).createTenant(tenantCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Удалить тенант.
         * @param {string} id ИД тенанта.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTenant(id: string, options?: any): AxiosPromise<void> {
            return TenantApiFp(configuration).deleteTenant(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить тенанта.
         * @param {string} id ИД тенанта.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenant(id: string, options?: any): AxiosPromise<TenantModel> {
            return TenantApiFp(configuration).getTenant(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить поиск тенантов.
         * @param {object} [filtration] Параметры фильтрации.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTenants(filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<TenantModelPaginationResponse> {
            return TenantApiFp(configuration).searchTenants(filtration, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить тенанта.
         * @param {string} id ИД тенанта.
         * @param {TenantUpdatingRequest} [tenantUpdatingRequest] Запрос на обновление тенанта.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTenant(id: string, tenantUpdatingRequest?: TenantUpdatingRequest, options?: any): AxiosPromise<object> {
            return TenantApiFp(configuration).updateTenant(id, tenantUpdatingRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TenantApi - object-oriented interface
 * @export
 * @class TenantApi
 * @extends {BaseAPI}
 */
export class TenantApi extends BaseAPI {
    /**
     * 
     * @summary Создать тенанта.
     * @param {TenantCreationRequest} [tenantCreationRequest] Запрос на создание тенанта.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public createTenant(tenantCreationRequest?: TenantCreationRequest, options?: any) {
        return TenantApiFp(this.configuration).createTenant(tenantCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Удалить тенант.
     * @param {string} id ИД тенанта.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public deleteTenant(id: string, options?: any) {
        return TenantApiFp(this.configuration).deleteTenant(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить тенанта.
     * @param {string} id ИД тенанта.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public getTenant(id: string, options?: any) {
        return TenantApiFp(this.configuration).getTenant(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить поиск тенантов.
     * @param {object} [filtration] Параметры фильтрации.
     * @param {number} [pageIndex] 
     * @param {number} [pageSize] 
     * @param {boolean} [withTotal] 
     * @param {number} [fromItemInclusive] 
     * @param {number} [toItemExclusive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public searchTenants(filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any) {
        return TenantApiFp(this.configuration).searchTenants(filtration, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить тенанта.
     * @param {string} id ИД тенанта.
     * @param {TenantUpdatingRequest} [tenantUpdatingRequest] Запрос на обновление тенанта.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public updateTenant(id: string, tenantUpdatingRequest?: TenantUpdatingRequest, options?: any) {
        return TenantApiFp(this.configuration).updateTenant(id, tenantUpdatingRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Создать пользователя.
         * @param {UserCreationRequest} [userCreationRequest] Запрос на создание пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (userCreationRequest?: UserCreationRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/membership/users`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof userCreationRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(userCreationRequest !== undefined ? userCreationRequest : {}) : (userCreationRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удалить пользователя.
         * @param {string} id ИД пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteUser.');
            }
            const localVarPath = `/api/v1/membership/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить пользователя.
         * @param {string} id ИД пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getUser.');
            }
            const localVarPath = `/api/v1/membership/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить поиск пользователей.
         * @param {object} [filtration] Параметры фильтрации.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchUsers: async (filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/membership/users`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (filtration !== undefined) {
                localVarQueryParameter['filtration'] = filtration;
            }

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить пользователя.
         * @param {string} id ИД пользователя.
         * @param {UserUpdatingRequest} [userUpdatingRequest] Запрос на обновление пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (id: string, userUpdatingRequest?: UserUpdatingRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateUser.');
            }
            const localVarPath = `/api/v1/membership/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof userUpdatingRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(userUpdatingRequest !== undefined ? userUpdatingRequest : {}) : (userUpdatingRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Создать пользователя.
         * @param {UserCreationRequest} [userCreationRequest] Запрос на создание пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(userCreationRequest?: UserCreationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserCreationResponse>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).createUser(userCreationRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Удалить пользователя.
         * @param {string} id ИД пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).deleteUser(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Получить пользователя.
         * @param {string} id ИД пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModel>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).getUser(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Выполнить поиск пользователей.
         * @param {object} [filtration] Параметры фильтрации.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchUsers(filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModelPaginationResponse>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).searchUsers(filtration, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Обновить пользователя.
         * @param {string} id ИД пользователя.
         * @param {UserUpdatingRequest} [userUpdatingRequest] Запрос на обновление пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(id: string, userUpdatingRequest?: UserUpdatingRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).updateUser(id, userUpdatingRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Создать пользователя.
         * @param {UserCreationRequest} [userCreationRequest] Запрос на создание пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(userCreationRequest?: UserCreationRequest, options?: any): AxiosPromise<UserCreationResponse> {
            return UserApiFp(configuration).createUser(userCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Удалить пользователя.
         * @param {string} id ИД пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(id: string, options?: any): AxiosPromise<void> {
            return UserApiFp(configuration).deleteUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить пользователя.
         * @param {string} id ИД пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(id: string, options?: any): AxiosPromise<UserModel> {
            return UserApiFp(configuration).getUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить поиск пользователей.
         * @param {object} [filtration] Параметры фильтрации.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchUsers(filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<UserModelPaginationResponse> {
            return UserApiFp(configuration).searchUsers(filtration, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить пользователя.
         * @param {string} id ИД пользователя.
         * @param {UserUpdatingRequest} [userUpdatingRequest] Запрос на обновление пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(id: string, userUpdatingRequest?: UserUpdatingRequest, options?: any): AxiosPromise<object> {
            return UserApiFp(configuration).updateUser(id, userUpdatingRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary Создать пользователя.
     * @param {UserCreationRequest} [userCreationRequest] Запрос на создание пользователя.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public createUser(userCreationRequest?: UserCreationRequest, options?: any) {
        return UserApiFp(this.configuration).createUser(userCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Удалить пользователя.
     * @param {string} id ИД пользователя.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteUser(id: string, options?: any) {
        return UserApiFp(this.configuration).deleteUser(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить пользователя.
     * @param {string} id ИД пользователя.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUser(id: string, options?: any) {
        return UserApiFp(this.configuration).getUser(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить поиск пользователей.
     * @param {object} [filtration] Параметры фильтрации.
     * @param {number} [pageIndex] 
     * @param {number} [pageSize] 
     * @param {boolean} [withTotal] 
     * @param {number} [fromItemInclusive] 
     * @param {number} [toItemExclusive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public searchUsers(filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any) {
        return UserApiFp(this.configuration).searchUsers(filtration, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить пользователя.
     * @param {string} id ИД пользователя.
     * @param {UserUpdatingRequest} [userUpdatingRequest] Запрос на обновление пользователя.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateUser(id: string, userUpdatingRequest?: UserUpdatingRequest, options?: any) {
        return UserApiFp(this.configuration).updateUser(id, userUpdatingRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


