import React, { useState, useEffect } from 'react';
import { Button, Result, Spin, Card } from 'antd';
import { StatusCodes } from 'http-status-codes';

import { logger } from '../utils/loggerUtil';
import { getQueryVariable } from '../utils/queryUtil';
import { ErrorInfoModel } from '../../api';
import { accountApi } from '../apis';

const ErrorInfo: React.FC<{}> = () => {
  const errorId = getQueryVariable('errorId');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState({} as ErrorInfoModel);

  const loadDataAsync = async () => {
    try {
      const response = await accountApi.getErrorInfo(errorId);
      if (response.status === StatusCodes.OK) {
        setError(response.data);
      } else {
        logger.error('Ошибка загрузки данных', errorId, response.statusText);
        setError({ message: response.statusText });
      }
    } catch (e) {
      logger.error('Ошибка загрузки данных', errorId, e);
      setError({ message: e.messsage });
    }
    setLoading(false);
  };
  const loadData = () => {
    loadDataAsync();
  };
  useEffect(loadData, []);

  const getError = () => {
    if (loading) {
      return <Result extra={<Spin size="large" />} status="error" />;
    }

    return (
      <Result
        extra={[
          <Button key="return" href="/" type="link">
            Вернуться на страницу входа
          </Button>,
        ]}
        status="error"
        subTitle={error.description}
        title={error.message}
      />
    );
  };

  return <Card>{getError()}</Card>;
};

export default ErrorInfo;
