import React from 'react';
import { Layout } from 'antd';

import { LogoElmaBot } from '../assets';

const { Content } = Layout;

const AuthLayout: React.FC<{}> = (props) => {
  const { children } = props;

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <div className="auth-layout-logo-container">
        <LogoElmaBot />
      </div>
      <Content style={{ display: 'flex', justifyContent: 'center' }}>{children}</Content>
    </Layout>
  );
};

export default AuthLayout;
