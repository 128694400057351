import React from 'react';

import LogoutPanel from '../components/LogoutPanel';

const LogoutPage: React.FC<{}> = () => {
  return (
    <div>
      <LogoutPanel />
    </div>
  );
};

export default LogoutPage;
