import { createBrowserHistory } from 'history';
import React from 'react';
import { Redirect, Route, Router, Switch } from 'react-router';

import './App.less';
import AuthLayout from './layouts/AuthLayout';
import InfoPage from './pages/InfoPage';
import LoginPage from './pages/LoginPage';
import LogoutPage from './pages/LogoutPage';
import ErrorPage from './pages/ErrorPage';

export const browserHistory = createBrowserHistory();

function App() {
  return (
    <Router history={browserHistory}>
      <Switch>
        <Route
          exact
          path="/app/info"
          render={() => (
            <AuthLayout>
              <InfoPage />
            </AuthLayout>
          )}
        />
        <Route
          exact
          path="/app/login"
          render={() => (
            <AuthLayout>
              <LoginPage />
            </AuthLayout>
          )}
        />
        <Route
          exact
          path="/app/logout"
          render={() => (
            <AuthLayout>
              <LogoutPage />
            </AuthLayout>
          )}
        />
        <Route
          exact
          path="/app/error"
          render={() => (
            <AuthLayout>
              <ErrorPage />
            </AuthLayout>
          )}
        />
        <Route path="*">
          <Redirect to="/app/login" />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
