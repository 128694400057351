import React, { useEffect, useState } from 'react';
import { Form, Button, Modal, Input } from 'antd';
import * as store from 'store';

import { LogoElma365 } from '../assets';

const PARAMETER_PREFIX = 'p.';

const getChallengeUrl = (scheme: string, parameters: Record<string, string>, returnUrl: string | undefined) => {
  const queryStringParams: Record<string, string> = {
    scheme,
    ...parameters,
  };
  if (returnUrl) {
    queryStringParams.returnUrl = returnUrl;
  }
  const urlSearchParams = new URLSearchParams(queryStringParams);
  return `/api/v1/identity/providers/challenge?${urlSearchParams}`;
};

interface IAuthProviderParameter {
  label: string;
  value?: string;
  required: boolean;
  disabled: boolean;
  visible: boolean;
}

export interface IAuthProviderSettings {
  scheme: string;
  title: string;
  image: string;
  visible: boolean;
  parameters: Record<string, IAuthProviderParameter>;
}

export interface IAuthProviderProps {
  settings: IAuthProviderSettings;
  returnUrl: string | undefined;
}

const AuthProvider: React.FC<IAuthProviderProps> = ({ settings, returnUrl }) => {
  const [form] = Form.useForm();
  const [withParameters, setWithParameters] = useState(false);

  const buttonTitle = `Войти, используя свою учетную запись ${settings.title}`;

  const loadData = () => {
    const parameters = settings.parameters || {};
    const formFields: Record<string, string> = {};
    for (const parameterName of Object.keys(parameters)) {
      const parameter = parameters[parameterName];
      const fieldName = `${PARAMETER_PREFIX}${parameterName}`;
      const fieldValue = !parameter.disabled && parameter.visible ? store.get(fieldName) : undefined;
      formFields[fieldName] = fieldValue || parameter.value || '';
    }
    form.setFieldsValue(formFields);
  };
  useEffect(loadData, []);

  const onFinishLogin = async () => {
    const parameters = settings.parameters || {};
    const formFields = await form.validateFields();
    for (const parameterName of Object.keys(parameters)) {
      const parameter = parameters[parameterName];
      const fieldName = `${PARAMETER_PREFIX}${parameterName}`;
      if (!parameter.disabled && parameter.visible) {
        store.set(fieldName, formFields[fieldName]);
      } else {
        formFields[fieldName] = parameter.value || '';
      }
    }
    window.location.href = getChallengeUrl(settings.scheme, formFields, returnUrl);
  };

  const onStartLogin = async () => {
    const parameters = settings.parameters || {};
    if (Object.values(parameters).some((p) => p.visible)) {
      setWithParameters(true);
    } else {
      await onFinishLogin();
    }
  };

  const onCancelLogin = () => {
    setWithParameters(false);
  };

  const renderLogo = () => {
    switch (settings.image) {
      case 'logo-elma365':
        return <LogoElma365 />;
      default:
        return <span>{settings.title}</span>;
    }
  };

  const renderParameters = () => {
    const parameters = settings.parameters || {};
    return Object.keys(parameters).map((name) => {
      const parameter = parameters[name];
      if (!parameter.visible) {
        return null;
      }

      const rules = [];
      if (parameter.required) {
        rules.push({
          required: true,
          message: 'Пожалуйста, укажите значение',
        });
      }

      return (
        <Form.Item key={name} label={parameter.label} name={`${PARAMETER_PREFIX}${name}`} rules={rules}>
          <Input disabled={parameter.disabled} />
        </Form.Item>
      );
    });
  };

  return (
    <React.Fragment>
      <Button className="auth-provider-button" title={buttonTitle} type="primary" onClick={onStartLogin}>
        Войти через {renderLogo()}
      </Button>
      <Modal
        cancelText="Отмена"
        getContainer={false}
        okText="Войти"
        title="Параметры входа"
        visible={withParameters}
        onCancel={onCancelLogin}
        onOk={onFinishLogin}
      >
        <Form form={form} name={`${settings.scheme}_form`} onFinish={onFinishLogin}>
          {renderParameters()}
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default AuthProvider;
